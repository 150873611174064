import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Medication, Patient } from '@mapuilabs/hv-interfaces';
import { TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { DrugPrescriptionBuilder } from '@services/vidal/builders/drug-prescription-builder';
import { PriscaRequestBuilder } from '@services/vidal/builders/prisca-request-builder';
import { PriscaRequest } from '@services/vidal/entities/prisca-request';
import { VidalUcdPair } from '@services/vidal/entities/vidal-ucd-pair';
import { VidalPriscaService } from '@services/vidal/vidal-prisca.service';
import { VidalMapper } from '@services/vidal/vidal.mapper';
import { VidalService } from '@services/vidal/vidal.service';
import { SnackbarService } from '@shared/services/snackbar/snackbar.service';
import { GroupByPipe } from 'ngx-pipes';
import { VidalVmpPair } from '@mapuilabs/vidal-client/dist/classes/vidal-vmp-pair';

@Component({
    selector: 'hv-vidal-securisation',
    templateUrl: './vidal-securisation.component.html',
    styleUrls: ['./vidal-securisation.component.scss'],
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'vidal' }]
})
export class VidalSecurisationComponent implements OnInit {
    isLoading = false;
    @ViewChild('divContent') iframeElement: ElementRef;
    private lastRequest: PriscaRequest;
    public showAlert: boolean = false;

    constructor(
        @Inject(TRANSLOCO_SCOPE) public scope: string,
        private priscaService: VidalPriscaService,
        private vidalService: VidalService,
        @Inject(MAT_DIALOG_DATA)
        private data: { medications: (Medication | Medication[])[]; patient: Patient; hvKeys: boolean },
        private vidalMapper: VidalMapper,
        private snackbar: SnackbarService,
        private groupByPipe: GroupByPipe
    ) {}

    ngOnInit() {
        this.vidalService.getVidalIds(this.medications, this.data.hvKeys).subscribe((result) => {
            this.buildRequest(result.filter((e) => e));
        });
    }

    get medications() {
        return this.data.medications.flat();
    }

    buildRequest(vidalIds: (VidalVmpPair | VidalUcdPair)[]): void {
        const drugPrescriptionBuilder = new DrugPrescriptionBuilder(this.vidalMapper);
        const priscaBuilder = new PriscaRequestBuilder();

        vidalIds.forEach((pair) => {
            let drug = null;
            if (pair['ucd13']) {
                drug = this.medications.find((e) => e.drug['ucd13'] == pair['ucd13']);
            } else if (pair['medicabase']) {
                drug = this.medications.find((e) => e.drug['medicabaseId'] == pair['medicabase']);
            }

            if (drug === undefined) {
                this.snackbar.showCustomErrorMsg(`${this.scope}.SNACKBAR.ERROR_MESSAGE`);
                console.error(`ERROR_MISSING_VIDAL_DRUG - ${JSON.stringify(pair)}`);
                return;
            }
            let prefix = 'vidal://';
            if (pair['ucd13']) {
                prefix += 'ucd/';
            } else if (pair['medicabase']) {
                prefix += 'vmp/';
            }
            const fullVidalId = prefix + pair.vidalId;
            drugPrescriptionBuilder.setVidalUri(fullVidalId);
            const groupedPosology = this.groupByPipe.transform(drug.posology, 'sequence');
            const keys = Object.keys(groupedPosology);
            keys.forEach((key) => {
                const drugPrescriptionLine = this.priscaService.parsePrescription(
                    fullVidalId,
                    drug,
                    groupedPosology[key]
                );
                drugPrescriptionBuilder.addPrescriptionLine(drugPrescriptionLine);
            });
        });
        const drugs = drugPrescriptionBuilder.build();
        const request = priscaBuilder.setPatient(this.data.patient).addPrescriptions(drugs).build();
        this.priscaService.getPrisca(request, this.data.hvKeys).subscribe({
            next: (html) => {
                this.setContent(html);
                this.lastRequest = request;
            },
            error: (error) => {
                if (error.status === 500) {
                    this.showAlert = true;
                }
            }
        });
    }

    setContent(content: string): void {
        const iframeDocument = this.iframeElement.nativeElement.contentDocument;
        iframeDocument.open();
        iframeDocument.write(content);
        iframeDocument.close();
    }

    launchDrugsInteractions(): void {
        const newWindow = window.open('about:blank', 'prisca');
        newWindow.document.write(
            '<html>\n<body>\n<form name=\'prisca\' action="https://api.vidal.fr/rest-prisca/api/redundancies/index" method="post" enctype="application/x-www-form-urlencoded">\n' +
                '   <input name="prescription" type="hidden" value=\'' +
                this.priscaService.requestToString(this.lastRequest) +
                "'>\n" +
                '' +
                '</form>\n</body>\n</html>'
        );
        newWindow.document.close();
        newWindow.document.forms['prisca'].submit();
    }
}
